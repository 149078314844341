import React from "react"
import img from "../../../static/images/icons/cta_entregas/icon_gestion.svg"
import "../../styles/index_cta.css"
import ActiveCampaignFormInCloud from '../ActiveCampaignFormInCloud'

const IndexCTALanding = () => {
  return (
    <div className="cta-landing">
        <div className="cta-content">
          <div className="cta-img"><img src={img} alt="Estrategias de Migración Cloud"></img></div>
          <div className="cta-text">
            <p className="title">Descubre nuestra última entrega y muchas más sobre Cloud e Infraestructuras suscribiéndote a nuestra newsletter </p>
            <p className="entrega">Gestión y optimización de costes</p>
          </div>
        </div>
      <ActiveCampaignFormInCloud/>
    </div>
  )
}
export default IndexCTALanding

