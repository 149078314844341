import React from "react"
import { Helmet } from "react-helmet"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import "../styles/style.css"
import CTALanding from "../components/index/cta_cloud"
import ServiciosCloud from "../components/services/servicios_cloud"

const CloudInfraestructura = () => {
  const data = useStaticQuery(graphql`
    query Images_servicios {
      image: allFile(
        filter: { relativeDirectory: { eq: "servicios" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Soluciones Cloud para Empresas | Qualoom</title>
        <meta name="title" content="Soluciones Cloud para Empresas | Qualoom"/>
        <meta name="description" content="En Qualoom nos encargamos de Servicios Cloud para Empresas, Infraestructuras Cloud y Migración y soluciones en la Nube. ¡Consúltanos e Infórmate YA!"/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/cloud-infraestructura/"/>
        <link rel="canonical" href="https://www.qualoom.es/cloud-infraestructura/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/cloud-infraestructura/"/>
        <meta property="og:title" content="Soluciones Cloud para Empresas | Qualoom"/>
        <meta property="og:description" content="En Qualoom nos encargamos de Servicios Cloud para Empresas, Infraestructuras Cloud y Migración y soluciones en la Nube. ¡Consúltanos e Infórmate YA!"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/4Ew2pO6bm28Sji6rSXYTIV/0ef1dfe088fa1afe26106941a9d01234/tw_cards_cloud.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/cloud-infraestructura/"/>
        <meta property="twitter:title" content="Soluciones Cloud para Empresas | Qualoom"/>
        <meta property="twitter:description" content="En Qualoom nos encargamos de Servicios Cloud para Empresas, Infraestructuras Cloud y Migración y soluciones en la Nube. ¡Consúltanos e Infórmate YA!"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/4Ew2pO6bm28Sji6rSXYTIV/0ef1dfe088fa1afe26106941a9d01234/tw_cards_cloud.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/cloud-infraestructura/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Cloud & Infraestructura", "item": {"@id": "https://www.qualoom.es/cloud-infraestructura/", "name": "Cloud & Infraestructura"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-servicios">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Soluciones Cloud para empresas (AWS, Azure y GCP)</h1>
              <p>Advanced Consulting Partner de Amazon Web Services, Azure y Google Cloud Platform con más de 10 años de experiencia en proyectos de migración a la gran nube, Reseller en todos ellos y miembro del programa de competencias de AWS: Digital Customer Experience Competence y Migration/DevOps Competence permiten que cientos de clientes hayan confiado en nosotros para impulsar, asegurar y evolucionar sus servicios.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <CTALanding></CTALanding>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿Qué son los servicios Cloud y por qué son tan importantes?</h2><hr></hr>
          <div className="section-main">
            <div className="section-img cloud"></div>
            <div className="section-text">
              <p>Los <b>servicios Cloud</b> son aquellos servicios ofrecidos por <b>proveedores especializados en tecnología</b> que permiten a las empresas que requieren disponer de herramientas, utilidades y aplicaciones para su operativa diaria, utilizarlos en las infraestructuras ofrecidas y mantenidas por esos proveedores especializados a través de un modelo de pago por uso.</p>
              <p>Este conjunto de herramientas, utilidades y servicios en sí mismos, <b>permiten crear arquitecturas operativas tecnológicas más complejas para la utilización de aplicaciones, almacenamiento de grandes cantidades de información, el procesamiento de video, imagen</b> y un sin fin de casos de uso más.</p>
            </div>
          </div><br/>
          <p>Estos casos de usos se traducen en la puesta a disposición de servicios por casuística, por ejemplo, <b>servicios Cloud </b>orientados a:</p>
          <ul className="list-col-3">
            <li>Almacenamiento de archivos</li>
            <li>Seguridad de la información</li>
            <li>Redes y enrutamiento</li>
            <li>IoT</li>
            <li>Machine learning</li>
            <li>Inteligencia artificial</li>
            <li>Servicios industriales</li>
            <li>Herramientas colaborativas</li>
            <li>Bases de datos</li>
            <li>Otros</li>
          </ul>
        </div>
      </div>
      <div className="services-main gray">
        <div className="services-main-content">
          <h2>Qualoom, Partner de los principales Cloud<br/>(AWS, Azure y Google Cloud Platform)</h2><hr></hr>
          <p>Qualoom Expertise Technology se ha posicionado estratégicamente como uno de los principales partners a nivel nacional para el <b> Cloud de Amazon Web Services como partner Advanced y como Partner de primer nivel para Azure y Google Cloud Platform</b>, además de ofrecer la posibilidad de actuar como Reseller en todos ellos.</p>
          <h3>Infraestructuras Cloud en Amazon Web Services, Azure y Google Cloud</h3>
          <p>Las infraestructuras Cloud en estos proveedores <b>son las más seguras, amplias y confiables</b>, ofreciendo una gran variedad de centros de datos y regiones por todo el mundo.</p>
          <p>Entre los principales beneficios de estos proveedores Cloud destacan:</p>
          <ul>
            <li><b>Los requisitos de seguridad más exigentes</b> que garantizan la confidencialidad, integridad y disponibilidad de los datos</li>
            <li><b>Amplia disponibilidad de red</b> desde cualquier otro proveedor Cloud</li>
            <li><b>Alto rendimiento</b> gracias a la baja latencia, baja pérdida de paquetes y alta calidad de la red por la conectividad de sus centros de datos y regiones</li>
            <li><b>Presencia global</b></li>
            <li><b>Alta escalabilidad</b></li>
            <li><b>Flexibilidad de elección</b> sobre cómo y dónde ejecutar las cargas de trabajo</li>
            <li><b>Calidad del soporte especializado y su equipo técnico</b></li>
          </ul>
        </div>
      </div>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿Qué servicios de Cloud ofrece Qualoom?</h2>
          <hr></hr>
          <h3>Servicios Cloud para empresas</h3><br/>
          <ServiciosCloud/>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default CloudInfraestructura
